import { computed, defineComponent, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
export default defineComponent({
    setup: function () {
        var authStore = inject('authState');
        var configurationStore = inject('configurationStore');
        var router = useRouter();
        var goTo = function (url) {
            router.push({ name: url });
        };
        var goToPoinstRecord = function () {
            configurationStore.state.showProfileBox = false;
            goTo('record.index');
        };
        var appVersion = ref('');
        if (Capacitor.isNativePlatform()) {
            AppVersion.getVersionNumber().then(function (appversion) {
                appVersion.value = appversion;
            });
        }
        return {
            userData: computed(function () { return authStore.state.userData; }),
            goToPoinstRecord: goToPoinstRecord,
            appVersion: appVersion
        };
    },
});
