import { createRouter, createWebHistory } from '@ionic/vue-router'
import routes from './routes'
import authStore from '@/store/auth.store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { loadCitiServiPixel } from '../../../commonvue/src/assets/scripts/citiservipixel'
import axios from '../config/axiosConfig'
import { Storage } from '@capacitor/storage'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

type AuthData = {
    isLoggedIn: boolean
    appToken: string
    authToken: string
}


router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        console.log(to.path)
        if (to.hash.indexOf('#/testVue/')>-1){
            if (to.hash.indexOf('?')>-1){
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                var testId = to.hash.replace('#/testVue/','')
                testId = testId.substring(0,testId.indexOf('?'))
                console.log('params '+params)
                next('app/test/'+testId+'/show?'+params)
            } else {
                const testId = to.hash.replace('#/testVue/','').substring(0,to.hash.indexOf('?'))
                console.log('Detected old test url, proceeding to redirect to /app/test/'+testId+'/show')
                next('app/test/'+testId+'/show')
            }
        } else if (to.hash.indexOf('#/entrar')>-1){
            if (to.hash.indexOf('?')>-1) {
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                next('entrar?'+params)
            } else {
                next()
            }
        } else if (to.hash.indexOf('#/registro')>-1){
            console.log(to)
            console.log('Detected old register url, proceeding to redirect to /register')
            if (to.hash.indexOf('?')>-1){
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                console.log('params '+params)
                next('register?'+params)
            } else {
                next('register')
            }
            //const paramsJSON = queryStringToJSON(params);
        } else if (to.hash.indexOf('#/contratos')>-1){
            console.log(to)
            console.log('Detected old powerteam url, proceeding to redirect to powerteam')
            var contractId = to.hash.replace('#/contratos/','')
            contractId = contractId.substring(0,contractId.length)
            window.location.href = "https://powerteam.wearetesters.com/#/contratos/"+contractId
        } else if (to.path.startsWith('/empresas')){
            window.location.href = "https://suite.wearetesters.com"
        } else if (to.path.startsWith('/powerteam')){
            window.location.href = "https://powerteam.wearetesters.com"
        } else {
            next()
        }
    }   
)

const guard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    authData: AuthData
): Promise<void> => {
    const needs2fa = await Storage.get({key:'needs2FA'})
    if(
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== "/2fa" &&
        needs2fa.value
    ){
        return next({path:'/2fa'})
    }
    else if(authData.isLoggedIn &&
        to.path == "/2fa" &&
        needs2fa.value){
        return next()
    }
    if (
        to.path === '/entrar' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return next({ name: 'tests.index' })
    }

    if (
        authData.isLoggedIn
    ) {
        loadCitiServiPixel(authStore.state.userData.email)
    }

    if (
        to.path === '/login' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return next({ name: 'tests.index' })
    }
    if (
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== '/verify-mobile' &&
        to.path !== '/unique-validation' &&
        ((!authStore.state.userData.mobileValidated &&
            authStore.state.userData.mobileValidationRequired &&
            authStore.state.userData.mobileNum != null) ||
            !authStore.state.userData.profileCompleted)
    ) {
        return next({ name: 'verify-mobile' })
    }
    if (
        to.path !== '/register' &&
        to.path !== '/forgot-password' &&
        to.path !== '/login' &&
        to.path !== '/verify-email' &&
        !authData.isLoggedIn 
    ) {
        return next({ path: '/login' })
    }

    next()
}



router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        if (to.path == '/entrar' && (typeof to.query.authToken === 'string' && to.query.authToken !== "")) {
            Storage.set({ key: 'authToken', value: to.query.authToken });
            Storage.set({ key: 'appToken', value: 'appTokenTemp' });
        }

        await authStore.loadStorageTokens()

        if (authStore.state.isLoggedIn && !authStore.state.deviceRegistered) {
            authStore.setDeviceRegistered()
        }

        const authData: AuthData = {
            isLoggedIn: authStore.state.isLoggedIn,
            appToken: authStore.state.appToken,
            authToken: authStore.state.authToken,
        }

        axios.defaults.headers.common['authToken'] = authData.authToken
        axios.defaults.headers.common['appToken'] = authData.appToken
        const needs2fa = await Storage.get({key:'needs2FA'})
        if (authData.authToken !== '' && !authStore.state.userDataLoaded && !needs2fa.value) {
            await authStore.getUserDetails()
        }
        guard(to, from, next, authData)
    }
)

export default router
