import ApiService from '@/service'

  const state = {
    lov:{
      provinces:[],
      regions:[],
      nielsen:[],
      identityDocTypes: []
    },
  }

  const getters = {
    provinces(state: any){
      return state.lov.provinces
    },
    regions(state: any){
      return state.lov.regions
    },
    nielsenAreas(state: any){
      return state.lov.nielsen
    },
    identityDocTypes(state: any){
      return state.lov.identityDocTypes
    },
    company_sector(state: any){
      return state.lov.company_sector
    },
    countries(state: any){
      return state.lov.countries
    }
  }

  const actions = {
    async getProvinces(context: any, payload: any) {
      return ApiService
      .get('/lovs/provinces/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          state.lov.provinces = data;
          return data;
        })
        .catch(({response}: any) => {
          return response;
        })
    },
    async getRegions(context: any, payload: any) {
      return ApiService
        .get('/lovs/regions/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          context.commit('setStateLovRegions',data)
          return data;
        })
        .catch(({response}: any) => {
          return response;
        })
    },
    async getNielsenAreas(context: any, payload: any) {
      return ApiService
        .get('/lovs/nielsen/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          context.commit('setStateLovNielsenAreas',data)
          return data;
        })
        .catch(({response}: any) => {
          return response;
        })
    },
    async getIdentityDocTypes(context: any) {
      return ApiService
        .get('/lovs/identity_doc_type/elements', null)
        .then(({data}: any) => {
          state.lov.identityDocTypes = data;
          return data;
        })
        .catch(({response}: any) => {
          return response;
        })
    },
    async getLovElement(context: any, payload: any){
      return ApiService
        .get('/lovs/'+payload.lovName+'/elements', null)
        .then(({data}: any) => {
          context.commit('setStateLovElements',{data:data, lovName:payload.lovName})
          return data;
        })
        .catch(({response}: any) => {
          return response;
        })
    }

  }

  const mutations = {
    setStateLovProvinces(state: any,data: any){
      state.lov.provinces = data;
    },
    setStateLovRegions(state: any,data: any){
      state.lov.regions = data;
    },
    setStateLovNielsenAreas(state: any,data: any){
      state.lov.nielsenAreas = data;
    },
    setStateIdentityDocTypes(state: any,data: any){
      state.lov.identityDocTypes = data;
    },
    setStateLovElements(state: any,data: any){
      state.lov[data.lovName] = data.data;
    },
  }

export default{
  actions,
  state,
  getters,
  mutations
}
