import { computed, defineComponent, inject } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    setup: function () {
        var authStore = inject('authState');
        var router = useRouter();
        var goTo = function (url) {
            router.push({ name: url });
        };
        return {
            userData: computed(function () { return authStore.state.userData; }),
            goTo: goTo,
        };
    },
});
