import { computed, defineComponent, inject, ref } from 'vue';
import ProfilePictureBox from './ProfilePictureBox.vue';
import ProfileProgressBar from './ProfileProgressBar.vue';
import ProfilePointsBoxMobile from './ProfilePointsBoxMobile.vue';
import { onClickOutside } from '@vueuse/core';
export default defineComponent({
    components: { ProfilePictureBox: ProfilePictureBox, ProfileProgressBar: ProfileProgressBar, ProfilePointsBoxMobile: ProfilePointsBoxMobile },
    setup: function () {
        var authStore = inject('authState');
        var configurationStore = inject('configurationStore');
        var target = ref(null);
        onClickOutside(target, function (event) {
            if (event.target !== null && event.target.id !== 'profileIconContainer') {
                configurationStore.setShowProfileBox(false);
            }
        });
        return {
            userData: computed(function () { return authStore.state.userData; }),
            target: target,
        };
    },
});
