import { createI18n } from 'vue-i18n';
import { merge } from 'lodash';


/*Spain*/
import esExecutionMessages from '../../../commonvue/src/i18n/execution/es.json'
import esTestersMessages from '../../../commonvue/src/i18n/testers/es.json'
import esGeoMessages from '../../../commonvue/src/i18n/geographic/es.json'

/*Portugal*/
import ptExecutionMessages from '../../../commonvue/src/i18n/execution/pt.json'
import ptTestersMessages from '../../../commonvue/src/i18n/testers/pt.json'
import ptGeoMessages from '../../../commonvue/src/i18n/geographic/pt.json'

/*English*/
import enExecutionMessages from '../../../commonvue/src/i18n/execution/en.json'
import enTestersMessages from '../../../commonvue/src/i18n/testers/en.json'
import enGeoMessages from '../../../commonvue/src/i18n/testers/en.json'


const messages = {
    es: merge(esTestersMessages, esExecutionMessages, esGeoMessages),
    pt: merge(ptTestersMessages, ptExecutionMessages, ptGeoMessages),
    en: merge(enTestersMessages, enExecutionMessages, enGeoMessages)
}

const i18n = createI18n({
    fallbackLocale: 'es',
    globalInjection: true,
    messages: messages
})

export default i18n