var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, } from '@ionic/vue';
import { computed, inject, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import PaginationWat from '@/components/PaginationWat.vue';
import NotificationsLoading from '@/components/NotificationsLoading.vue';
export default {
    name: 'NotificationsModal',
    components: {
        IonPage: IonPage,
        IonHeader: IonHeader,
        IonTitle: IonTitle,
        IonToolbar: IonToolbar,
        IonButtons: IonButtons,
        IonButton: IonButton,
        IonContent: IonContent,
        IonList: IonList,
        IonItem: IonItem,
        IonLabel: IonLabel,
        PaginationWat: PaginationWat,
        NotificationsLoading: NotificationsLoading
    },
    setup: function () {
        var _this = this;
        var router = useRouter();
        var notifStore = inject('notificationsStore');
        var notifications = computed(function () { return notifStore.state.notifications || {}; });
        var page = ref(1);
        var pageSize = ref(10);
        var totalTests = computed(function () {
            var numericCount = Number(notifStore.state.notifications.count);
            return numericCount;
        });
        var chunk = ref(5);
        var chunksNavigation = ref('fixed');
        var loadNotifications = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, notifStore.getNotifications(page.value - 1, pageSize.value)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var target = ref(null);
        onClickOutside(target, function (event) {
            if (event.target !== null &&
                event.target.id !== 'showNotificationsButton') {
                notifStore.showNotifications(false);
            }
        });
        var showNotifications = function (state) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, notifStore.showNotifications(state)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); };
        var goToTest = function (id, type) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(type == 'REGULAR_TEST_AVAILABLE')) return [3 /*break*/, 2];
                        return [4 /*yield*/, showNotifications(false)];
                    case 1:
                        _a.sent();
                        router.push({ name: 'test.show', params: { id: id } });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            var desktop, mobile;
            return __generator(this, function (_a) {
                desktop = window.matchMedia('(min-width: 1000px)');
                mobile = window.matchMedia('(max-width: 1000px)');
                if (mobile.matches) {
                    pageSize.value = 10;
                    chunk.value = 5;
                    chunksNavigation.value = 'scroll';
                }
                else {
                    pageSize.value = 4;
                    chunk.value = 10;
                    chunksNavigation.value = 'fixed';
                }
                return [2 /*return*/];
            });
        }); });
        return {
            goToTest: goToTest,
            notifStore: notifStore,
            notifications: notifications,
            showNotifications: showNotifications,
            target: target,
            page: page,
            totalTests: totalTests,
            loadNotifications: loadNotifications,
            chunk: chunk,
            chunksNavigation: chunksNavigation
        };
    },
};
