
import { ref, computed, reactive, inject, onBeforeMount } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
    name: 'Redeem',
    setup() {
        const authStore: any = inject('authState')
        const exchangeStore: any = inject('exchangeStore')
        const type = computed(
            () => exchangeStore.state.redeemBox.selectedRedeemType
        )
        const target = ref(null)
        onClickOutside(target, (event: any) => {
            if (
                event.target !== null &&
                event.target.id !== 'exchangeBoxButton'
            ) {
                exchangeStore.setShowRedeem(false)
            }
        })
        const redeemFormData = reactive(
            exchangeStore.state.redeem.redeemFormData
        )

        const selectIsSelected = ref(false)

        onBeforeMount(() => {
            redeemFormData.email = authStore.state.userData.email
        })

        const paypalCheckAmount = () => {
            exchangeStore.state.redeem.modalLoading = true
            const data = {
                redeemType: exchangeStore.state.redeemBox.types.PAYPAL,
                pointsRedeem: parseInt(redeemFormData.amount),
            }
            exchangeStore.check(data)
        }

        const RedeemSubmit = () => {
            if (type.value == exchangeStore.state.redeemBox.types.PAYPAL) {
                paypalCheckAmount()
            } else {
                exchangeStore.state.redeem.modalLoading = true
                exchangeStore.state.redeem.redeemConfirmation.showSuccess =
                    false
                exchangeStore.state.redeem.redeemConfirmation.showError = false
                const data = {
                    redeemType: type.value,
                    pointsRedeem: parseInt(redeemFormData.amount),
                    testerRedeemData: {
                        identityDocType: {
                            elementId: parseInt(
                                authStore.state.userData.identityDocType
                                    .elementId
                            ),
                        },
                        identityDocNum: authStore.state.userData.identityDocNum,
                        name: authStore.state.userData.name,
                        surname1: authStore.state.userData.surname1,
                        surname2: authStore.state.userData.surname2,
                        address: authStore.state.userData.address,
                        locality: authStore.state.userData.locality,
                        postalCode: authStore.state.userData.postalCode,
                    },
                }
                exchangeStore.redeem(data)
            }
        }

        const paypalRedeemSubmit = () => {
            exchangeStore.state.redeem.modalLoading = true
            exchangeStore.state.redeem.redeemConfirmation.showSuccess = false
            exchangeStore.state.redeem.redeemConfirmation.showError = false
            const data = {
                redeemType: exchangeStore.state.redeemBox.types.PAYPAL,
                pointsRedeem: parseInt(redeemFormData.amount),
                paypalEmail: redeemFormData.email,
                testerRedeemData: {
                    identityDocType: {
                        elementId: parseInt(
                            authStore.state.userData.identityDocType.elementId
                        ),
                    },
                    identityDocNum: authStore.state.userData.identityDocNum,
                    name: authStore.state.userData.name,
                    surname1: authStore.state.userData.surname1,
                    surname2: authStore.state.userData.surname2,
                    address: authStore.state.userData.address,
                    locality: authStore.state.userData.locality,
                    postalCode: authStore.state.userData.postalCode,
                },
            }
            exchangeStore.paypalRedeem(data)
        }

        const minAmount = (type: string) => {
            switch (type) {
                case exchangeStore.state.redeemBox.types.PAYPAL:
                    return 10
                case exchangeStore.state.redeemBox.types.AMAZON:
                    return 10
                case exchangeStore.state.redeemBox.types.ATRAPALO:
                    return 25
                case exchangeStore.state.redeemBox.types.ASOS:
                    return 10
                default:
                    return 0
            }
        }

        const userValidData = () => {
            return (
                authStore.state.userData.name &&
                authStore.state.userData.surname1 &&
                authStore.state.userData.surname2 &&
                authStore.state.userData.bornDate &&
                authStore.state.userData.identityDocNum &&
                authStore.state.userData.postalCode &&
                authStore.state.userData.address &&
                authStore.state.userData.locality 
            )
        }

        const userValidEmail = () => {
            return (
                authStore.state.userData.email &&
                (!authStore.state.userData.emailNeedsValidation || authStore.state.userData.emailValidated)
            )
        }

        const userValidIdDocType = () => {
            if (authStore.state.userData.identityDocType != null && authStore.state.userData.identityDocType.elementId != null) {
                return true
            } else {
                return false
            }
        }

        return {
            userData: computed(() => authStore.state.userData),
            redeemConfirmation: reactive(
                exchangeStore.state.redeem.redeemConfirmation
            ),
            redeemFormData,
            modalLoading: computed(
                () => exchangeStore.state.redeem.modalLoading
            ),
            RedeemSubmit,
            paypalRedeemSubmit,
            type,
            redeemBox: computed(() => exchangeStore.state.redeemBox),
            minAmount,
            userValidData,
            userValidEmail,
            userValidIdDocType,
            selectIsSelected,
            target,
        }
    },
}
